// 薪酬详情列表
<template>
    <div class="baseBg reserveProject">
        <div
            style="padding:20px 20px 5px;background-color:#fff;border-raidus:0px"
        >
            <backUp></backUp>
        </div>
        <div
            class="listBox mainWhiteBg"
            style="position: relative;padding-top:5px;padding-bottom: 0px;
    margin-bottom: 20px;"
        >
            <div class="userInfoBox">
                <comUserInfo
                    :userId="searchForm.userId"
                    v-if="!searchForm.isOther"
                ></comUserInfo>
                <div class="otherUserInfo" v-else>
                    <span class="username">{{ $route.query.userName }}</span>
                    <span class="dept">{{ $route.query.dept }}</span>
                </div>

                <el-button type="primary topBtn" @click="showSalary">
                    {{ showMoney ? "隐藏" : "显示" }}薪资
                    <countdown
                        @countDowmEnd="countDowmEnd"
                        v-if="showMoney"
                    ></countdown>
                </el-button>
            </div>
        </div>

        <div class="listBox">
            <div class="tableBox">
                <el-table
                    :data="tableData"
                    ref="tableRef"
                    border
                    v-loading="isloading"
                    :height="tableHeight"
                    stripe
                >
                    <el-table-column type="index" width="50" label="序号">
                    </el-table-column>
                    <el-table-column
                        prop="payDate"
                        width="128"
                        label="付款日期"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="monthStr"
                        width="120"
                        label="工资月份"
                    >
                    </el-table-column>
                    <el-table-column label="个人收入" align="center">
                        <el-table-column
                            prop="salaryStandard"
                            width="120"
                            label="薪资标准"
                        >
                            <template slot-scope="scope">
                                {{
                                    showMoney
                                        ? commonJs.wageAbs(
                                              scope.row.salaryStandard
                                          )
                                        : "******"
                                }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="salaryMerit"
                            width="120"
                            label="绩效工资"
                        >
                            <template slot-scope="scope">
                                {{
                                    showMoney
                                        ? commonJs.wageAbs(
                                              scope.row.salaryMerit
                                          )
                                        : "******"
                                }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="subsidy"
                            width="120"
                            label="补贴"
                        >
                            <template slot-scope="scope">
                                {{
                                    showMoney
                                        ? commonJs.wageAbs(scope.row.subsidy)
                                        : "******"
                                }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="duringBonus"
                            width="120"
                            label="在职延续奖"
                        >
                            <template slot-scope="scope">
                                {{
                                    showMoney
                                        ? commonJs.wageAbs(
                                              scope.row.duringBonus
                                          )
                                        : "******"
                                }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="salaryFood"
                            width="120"
                            label="餐补"
                        >
                            <template slot-scope="scope">
                                {{
                                    showMoney
                                        ? commonJs.wageAbs(scope.row.salaryFood)
                                        : "******"
                                }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="salaryOther"
                            width="120"
                            label="其他"
                        >
                            <template slot-scope="scope">
                                {{
                                    showMoney
                                        ? commonJs.wageAbs(
                                              scope.row.salaryOther
                                          )
                                        : "******"
                                }}
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column
                        prop="deductionTotal"
                        width="120"
                        label="扣减"
                    >
                        <template slot-scope="scope">
                            {{
                                showMoney
                                    ? commonJs.wageAbs(scope.row.deductionTotal)
                                    : "******"
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column label="社保" align="center">
                        <el-table-column
                            prop="socialSecurityStaff"
                            width="120"
                            label="个人"
                        >
                            <template slot-scope="scope">
                                {{
                                    showMoney
                                        ? commonJs.wageAbs(
                                              scope.row.socialSecurityStaff
                                          )
                                        : "******"
                                }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="socialSecurityCompany"
                            width="120"
                            label="企业"
                        >
                            <template slot-scope="scope">
                                {{
                                    showMoney
                                        ? commonJs.wageAbs(
                                              scope.row.socialSecurityCompany
                                          )
                                        : "******"
                                }}
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="公积金" align="center">
                        <el-table-column
                            prop="accumulationFundStaff"
                            width="120"
                            label="个人"
                        >
                            <template slot-scope="scope">
                                {{
                                    showMoney
                                        ? commonJs.wageAbs(
                                              scope.row.accumulationFundStaff
                                          )
                                        : "******"
                                }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="accumulationFundCompany"
                            width="120"
                            label="企业"
                        >
                            <template slot-scope="scope">
                                {{
                                    showMoney
                                        ? commonJs.wageAbs(
                                              scope.row.accumulationFundCompany
                                          )
                                        : "******"
                                }}
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="税务" align="center">
                        <el-table-column
                            prop="personalIncomeTax"
                            width="120"
                            label="个人所得税"
                        >
                            <template slot-scope="scope">
                                {{
                                    showMoney
                                        ? commonJs.wageAbs(
                                              scope.row.personalIncomeTax
                                          )
                                        : "******"
                                }}
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="总计" align="center">
                        <el-table-column
                            prop="salary"
                            width="120"
                            label="应发工资"
                        >
                            <template slot-scope="scope">
                                {{
                                    showMoney
                                        ? commonJs.wageAbs(scope.row.salary)
                                        : "******"
                                }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="salary"
                            width="120"
                            label="实发合计"
                        >
                            <template slot-scope="scope">
                                {{
                                    showMoney
                                        ? commonJs.wageAbs(scope.row.salary)
                                        : "******"
                                }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="salaryTaxTotal"
                            width="120"
                            label="工资税费合计"
                        >
                            <template slot-scope="scope">
                                {{
                                    showMoney
                                        ? commonJs.wageAbs(
                                              scope.row.salaryTaxTotal
                                          )
                                        : "******"
                                }}
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="其他" align="center">
                        <el-table-column
                            prop="id"
                            label="操作"
                            align="center"
                            min-width="100"
                        >
                            <template slot-scope="scope">
                                <el-link
                                    type="primary"
                                    v-if="showMoney"
                                    @click="openpopAdjustSalary(scope.row)"
                                    >查看</el-link
                                >
                            </template>
                        </el-table-column>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <Pagination
                    :page="searchForm.page"
                    :limit="searchForm.size"
                    :total="pageTotal"
                    @pagination="paginationChange"
                />
            </div>
        </div>
        <popAddCompensation
            v-if="dialogVisible"
            :formData="rowData"
            :userId="searchForm.userId"
            @refresh="getList"
            @close="closeSystemDialog"
        ></popAddCompensation>

        <!-- 输入密码 -->
        <popPwd
            v-if="showInputPwd"
            @close="close"
            @updatePwd="updatePwd"
        ></popPwd>

        <!-- 调整薪资 -->
        <pop-add-single-salary
            v-if="popAdjustSalaryVisible"
            :detailParms="detailParm"
            @refresh="ayncSuccess"
            salaryOperationType="details"
            @close="closepopAdjustSalary"
        ></pop-add-single-salary>
    </div>
</template>

<script>
import PopAddSingleSalary from "../../../salary/components/popAddSingleSalary";
import backUp from "@/components/backUp";

import humanResourcesManageApi from "@/api/humanResourcesManageApi.js";
import Pagination from "@/components/Pagination"; // 分页
import countdown from "@/components/countdown"; // 倒计时
import popAddCompensation from "./componets/popAddCompensation"; //编辑新增工资条
import comUserInfo from "./componets/comUserInfo"; //个人信息 头像 职位那些
import popPwd from "./componets/popPwd"; // 填写密码
import rsa from "@/utils/encryption/rsa"; // RSA解密
export default {
    name: "compensationDetailContainer",
    props: [],

    components: {
        PopAddSingleSalary,
        backUp,
        Pagination,
        countdown,
        popAddCompensation,
        comUserInfo,
        popPwd,
    },

    data() {
        return {
            dialogVisible: false,
            isEditloading: false, // 编辑时拉取行数据
            fontRsaKey: {}, //生成 加密 公私钥
            searchForm: {
                date: ["", ""],
                userId: this.$route.query.id, //用户id
                isOther: this.$route.query.isOther, // 是否是其他人员
                page: 1,
                size: 20,
            },
            showMoney: false, //是否显示薪资
            showInputPwd: false, //是否显示输入密码弹框
            isEditInput: false, //当前是否为编辑时的输入密码操作  此时不更新列表
            showMoneyPwd: null, // 显示薪酬的密码
            pageTotal: 0,
            isloading: false,
            rowData: null,
            tableData: [],
            rsa,
            popAdjustSalaryVisible: false, // 调整薪资弹窗显示
        };
    },

    created() {},

    mounted() {
        // this.searchFormuserId = ;
        this.entryRsaKey();
    },

    methods: {
        /* 获取列表 */
        getList(searchForm = this.searchForm) {
            // if(!this.showMoneyPwd){return this.showInputPwd = true}//未输入密码时  直接弹出验证
            this.isloading = true;
            return new Promise((resolve) => {
                humanResourcesManageApi
                    .getSalaryHistoryTable(
                        Object.assign({}, searchForm, {
                            userId: this.$route.query.id,
                            latestSalaryStartTime: searchForm.date[0],
                            latestSalaryEndTime: searchForm.date[1],
                            publicKey: this.serverPublicKey,
                            // pwd:this.commonJs.rsaEncryption(this.$store.state.user.seeSalaryPwd || this.showMoneyPwd,this.serverPublicKey),
                            pwd: this.commonJs.rsaEncryption(
                                this.showMoneyPwd,
                                this.serverPublicKey
                            ),
                            frontPublicKey: this.fontRsaKey.publicKey,
                        })
                    )
                    .then((res) => {
                        this.isloading = false;
                        this.tableData = res.content.records.map((v) => {
                            return Object.assign(
                                {},
                                v,
                                this.setDecryptField(v)
                            );
                        });
                        this.pageTotal = res.content.total;
                        /* 设置缓存正确密码 */
                        this.$store.commit(
                            "SET_SEESALARYPWD",
                            this.showMoneyPwd ||
                                this.$store.state.user.seeSalaryPwd
                        );
                        resolve();
                    })
                    .catch(() => {
                        this.isloading = false;
                    });
            });
        },

        /*  获取服务款公钥  、  生成加解密公钥、私钥 */
        entryRsaKey() {
            this.$store.dispatch("getSalaryKey").then((v) => {
                this.fontRsaKey = v;
                this.$store.dispatch("GetPublicKey").then((v) => {
                    //后端生成公钥
                    this.serverPublicKey = v.content;
                    this.search();
                });
            });
        },

        // 搜索
        search() {
            this.defaultSearch();
        },

        // 分页改变
        paginationChange(e) {
            this.getList(Object.assign(this.searchForm, e));
        },

        /*  获取当前编辑行的数据   */
        getSalaryDetailByid(e) {
            /*  若还未解密数据并且无缓存密码  必须先输入密码  */
            this.isEditloading = true;
            humanResourcesManageApi
                .getSalaryDetailByid(
                    Object.assign(
                        {},
                        {
                            publicKey: this.serverPublicKey,
                            // pwd:this.commonJs.rsaEncryption(this.$store.state.user.seeSalaryPwd || this.showMoneyPwd,this.serverPublicKey),
                            pwd: this.commonJs.rsaEncryption(
                                this.showMoneyPwd,
                                this.serverPublicKey
                            ),
                            frontPublicKey: this.fontRsaKey.publicKey,
                            id: e.id,
                        }
                    )
                )
                .then((res) => {
                    this.isEditloading = false;
                    this.isEditInput = false;
                    let v = res.content;
                    this.rowData = Object.assign(
                        {},
                        e,
                        { title: e.title },
                        this.setDecryptField(v)
                    );
                    /* 设置查看薪酬密码 缓存 */
                    this.$store.commit(
                        "SET_SEESALARYPWD",
                        this.showMoneyPwd || this.$store.state.user.seeSalaryPwd
                    );
                    this.dialogVisible = true;
                })
                .catch(() => {
                    this.isEditloading = false;
                });
        },

        /* 对关键数据进行解密 */
        setDecryptField(v) {
            return {
                salaryStandard: rsa.rsaDecrypt(v.salaryStandard), // 薪资标准
                salaryMerit: rsa.rsaDecrypt(v.salaryMerit), // 绩效工资
                subsidy: rsa.rsaDecrypt(v.subsidy), // 补贴
                duringBonus: rsa.rsaDecrypt(v.duringBonus), // 在职延续奖
                salaryFood: rsa.rsaDecrypt(v.salaryFood), // 餐补
                salaryOther: rsa.rsaDecrypt(v.salaryOther), // 其他
                deductionTotal: rsa.rsaDecrypt(v.deductionTotal), // 扣减
                socialSecurityStaff: rsa.rsaDecrypt(v.socialSecurityStaff), // 个人
                socialSecurityCompany: rsa.rsaDecrypt(v.socialSecurityCompany), // 企业
                accumulationFundStaff: rsa.rsaDecrypt(v.accumulationFundStaff), // 个人
                accumulationFundCompany: rsa.rsaDecrypt(
                    v.accumulationFundCompany
                ), // 企业
                personalIncomeTax: rsa.rsaDecrypt(v.personalIncomeTax), // 个人所得税
                salary: rsa.rsaDecrypt(v.salary), // 应发工资
                salaryTaxTotal: rsa.rsaDecrypt(v.salaryTaxTotal), // 工资税费合计
            };
        },

        // 调整薪资 打开弹框
        openpopAdjustSalary(row) {
            this.detailParm = Object.assign({}, this.detailParm, {
                userId: row.userId,
                id: row.id,
                pwd: this.showMoneyPwd || null,
            });
            if (!this.showMoneyPwd) {
                return (this.showInputPwd = true);
            } //未输入密码时  直接弹出验证
            this.popAdjustSalaryVisible = true;
        },
        // 调整薪资 关闭弹框
        closepopAdjustSalary() {
            this.popAdjustSalaryVisible = false;
        },

        // 新增或編輯系統彈框 关闭
        closeSystemDialog() {
            this.dialogVisible = false;
            // this.defaultSearch();
        },

        /* 关闭弹框 */
        close() {
            this.showInputPwd = false;
        },

        // 默认查询第一页
        defaultSearch() {
            this.searchForm.page = 1;
            this.getList();
        },

        //显示金额
        showSalary() {
            /* 针对每次都让输入密码 */
            this.showMoney
                ? (this.showMoney = false)
                : (this.showInputPwd = true);
            this.isEditInput = false;

            /*  是否针对密码输入正确后就不需要输入  */
            // if(this.seeSalaryPwd){
            //     this.showMoney = !this.showMoney;
            //     this.showMoneyPwd = this.seeSalaryPwd;
            // } else {
            //     this.showInputPwd = true;
            // }
        },

        // 删除操作
        deleteTable(row) {
            this.isloading = true;
            humanResourcesManageApi
                .deleteSalary(row.id)
                .then(() => {
                    this.isloading = false;
                    this.$message({
                        type: "success",
                        message: "删除成功",
                    });
                    this.getList();
                })
                .catch(() => {
                    this.isloading = false;
                });
        },

        countDowmEnd() {
            this.showSalary();
        },

        /* 密码确认 */
        updatePwd(opt) {
            this.showMoneyPwd = opt.pwd;
            /*  此时非编辑操作事时才进行列表的更新  */
            if (this.isEditInput) {
                this.getSalaryDetailByid(this.editObjData);
                this.getList();
            } else {
                this.getList().then(() => {
                    this.showMoney = true;
                });
            }
        },
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 310 });
        },
        //查询薪酬的密码
        seeSalaryPwd() {
            return this.$store.state.user.seeSalaryPwd;
        },
    },

    watch: {},
};
</script>

<style lang="scss" scoped>
.reserveProject ::v-deep .el-tabs__nav-scroll {
    overflow: visible;
}
.reserveProject ::v-deep .el-tabs__nav-wrap {
    overflow: visible;
    height: 40px;
}

.userInfoBox {
    display: inline-block;
    width: 100%;
    position: relative;
    height: 90px;
}
.userInfoBox .userImg {
    border-radius: 50%;
    border: 1px solid #fff;
    position: absolute;
    left: 15px;
    top: 15px;
    width: 60px;
}

.userInfoBox .userName {
    position: absolute;
    left: 90px;
    top: 20px;
    font-size: 18px;
    font-family: "微软雅黑";
}

.userInfoBox .userPosition {
    position: absolute;
    left: 90px;
    top: 55px;
    font-size: 14px;
    font-family: "微软雅黑";
}

.userInfoBox .tag {
    position: absolute;
    left: 150px;
    top: 15px;
}
.mainWhiteBg .el-tabs__header {
    margin-bottom: 30px;
}

.topBtn {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 9;
}
.reserveProject .topBtn:nth-child(1) {
    right: 130px;
}
.otherUserInfo {
    line-height: 50px;
    padding: 15px;
    .username {
        font-size: 24px;
        margin-right: 35px;
    }
}
</style>

import { render, staticRenderFns } from "./selectPersonInWageDeptTree.vue?vue&type=template&id=c59a4102&scoped=true&"
import script from "./selectPersonInWageDeptTree.vue?vue&type=script&lang=js&"
export * from "./selectPersonInWageDeptTree.vue?vue&type=script&lang=js&"
import style0 from "./selectPersonInWageDeptTree.vue?vue&type=style&index=0&id=c59a4102&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c59a4102",
  null
  
)

export default component.exports
// 根据公司部门树选择人员 社保选择使用 因为有非公司以外人员 所以单独写的组件
包含离职人员 //获取组织结构和成员树(包含薪酬的其他人员)
<template>
    <div class="selectPersonInDeptTree">
        <el-cascader
            :value="value"
            ref="selectPerson"
            :placeholder="placeholder || '请选择处理人员'"
            :disabled="disabled"
            :show-all-levels="false"
            :filterable="filterable"
            :options="options"
            @change="change($event)"
            :props="{
                emitPath: false,
                label: 'label',
                multiple: multiple,
                value: 'id',
            }"
            clearable
        ></el-cascader>
    </div>
</template>

<script>
import { Loading } from "element-ui";
import selectOptsAPI from "@/api/selectOpts.js";
export default {
    name: "selectPersonInWageDeptTree",

    props: {
        value: String,
        // 提示语
        placeholder: {
            type: String,
        },
        // 是否可以多选，默认为false
        multiple: {
            type: Boolean,
            default: false,
        },
        //是否可搜索 默认false
        filterable: {
            type: Boolean,
            default: false,
        },
        // 是否禁用
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    components: {},

    data() {
        return {
            options: [],
        };
    },

    created() {},

    mounted() {
        // 公司下的联系人列表
        this.getPersonInDeptTree();
    },

    methods: {
        change: function(val) {
            this.$emit("input", val);
            this.$emit("change");
        },
        // 公司下的联系人列表
        getPersonInDeptTree() {
            let loading = Loading.service({
                target: document.querySelector(".selectPersonInDeptTree"),
            });
            return selectOptsAPI
                .getPersonInWageDeptTree()
                .then((res) => {
                    this.options = res.content;
                    loading.close();
                })
                .catch(() => {
                    loading.close();
                });
        },
    },

    computed: {
        // 选中的节点数据
        selectNode() {
            let opts = this.$refs["selectPerson"].getCheckedNodes();
            opts = opts.filter((item) => {
                return !item.hasChildren;
            });
            opts = opts.map((item) => {
                return {
                    label: item.label,
                    value: item.value,
                };
            });
            return opts;
        },
    },

    watch: {},
};
</script>

<style lang="scss" scoped>
// .selectPersonInDeptTree{

// }
</style>

// 薪酬详情列表
<template>
    <div v-loading="isloading">
        <el-image class="userImg"
                  :src="commonJs.showFileImg(userInfo.headPic) || require('@/assets/imgs/default_userPhoto.png') "
                  fit="cover"
                  alt="">
            <div slot="error"
                 class="image-slot">
                <img src="@/assets/imgs/default_userPhoto.png"
                     class="userImg_error"
                     alt="">
            </div>
        </el-image>
        <div class="userName">{{userInfo.userName || ''}}
            <el-tag class="tag"
                    style="margin-left:20px"
                    :type="getTagType(userInfo.workerStatus.name)">{{userInfo.workerStatus.text}}</el-tag>
        </div>

        <div class="userPosition">{{userInfo.job || ''}}</div>
    </div>

</template>

<script>
import humanResourcesManageApi from "@/api/humanResourcesManageApi.js";
export default {
    name: "comUserInfo",
    props: {
        userId: {},
    },

    watch: {
        //传入用户id
        userId: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                newVal && this.getDetail();
            },
        },
    },

    components: {},

    data() {
        return {
            isloading: false,
            userInfo: {
                workerStatus: {},
            }, //用户信息
        };
    },

    created() {},

    mounted() {},

    methods: {
        /* 获取用户详情 */
        getDetail() {
            this.isloading = true;
            humanResourcesManageApi
                .getUserInfoByUserId(this.userId)
                .then((res) => {
                    this.isloading = false;
                    this.userInfo = res.content;
                })
                .catch(() => {
                    this.isloading = false;
                });
        },

        //获取员工状态
        getTagType(type) {
            let status = "info";
            switch (type) {
            case "PROBATION":
                status = "";
                break;
            case "REGULAR":
                status = "success";
                break;
            case "WAIT_LEAVE":
                status = "warning";
                break;
            case "LEAVE":
                status = "danger";
                break;
            default:
                break;
            }
            return status;
        },
    },

    computed: {},
};
</script>

<style lang='scss' scoped>
.reserveProject ::v-deep .el-tabs__nav-scroll {
    overflow: visible;
}
.reserveProject ::v-deep .el-tabs__nav-wrap {
    overflow: visible;
    height: 40px;
}

.userInfoBox {
    display: inline-block;
    width: 100%;
    position: relative;
    height: 90px;
}
.userInfoBox .userImg {
    border-radius: 50%;
    border: 1px solid #fff;
    position: absolute;
    left: 15px;
    top: 15px;
    height: 60px;
    width: 60px;
}

.userInfoBox .userImg_error {
    height: 60px;
    width: 60px;
}

.userInfoBox .userName {
    position: absolute;
    left: 90px;
    top: 20px;
    font-size: 18px;
    font-family: "微软雅黑";
}

.userInfoBox .userPosition {
    position: absolute;
    left: 90px;
    top: 55px;
    font-size: 14px;
    font-family: "微软雅黑";
}

.userInfoBox .tag {
    // position: absolute;
    // left: 150px;
    // top: 15px;
}
.mainWhiteBg .el-tabs__header {
    margin-bottom: 30px;
}

.topBtn {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 9;
}
.reserveProject .topBtn:nth-child(1) {
    right: 130px;
}
</style>

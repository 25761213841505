// 新增/编辑工资条
<template>
    <el-dialog :close-on-click-modal="false"
               :title="form.title+'工资条'"
               :visible="true"
               width="1200px"
               :before-close="close">
        <el-form :model="form"
                 ref="form"
                 :rules="rules"
                 label-width="140px"
                 :inline="false"
                 size="normal">

            <el-row v-loading="isloading">
                <el-col :span="12">
                    <el-form-item label="日期"
                                  prop="payTime">
                        <el-date-picker v-model="form.payTime"
                                        type="month"
                                        format="yyyy-MM"
                                        value-format="yyyy-MM"
                                        placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="出勤天数">
                        <el-input v-model="form.attendanceDays"
                                  type='number'
                                  @change="((val) => {resetVal(val, 'attendanceDays')})"
                                  placeholder="请输入出勤天数"
                                  clearable />
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="薪资标准"
                                  prop="salaryStandard">
                        <el-input v-model="form.salaryStandard"
                                  type='number'
                                   @change="((val) => {resetVal(val, 'salaryStandard')})"
                                  placeholder="请输入薪资标准"
                                  clearable />
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="当月基础工资">
                        <el-input v-model="form.salaryBase"
                                  type='number'
                                   @change="((val) => {resetVal(val, 'salaryBase')})"
                                  placeholder="请输入当月基础工资"
                                  clearable />
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="当月岗位工资">
                        <el-input v-model="form.salaryPost"
                                  type='number'
                                  @change="((val) => {resetVal(val, 'salaryPost')})"
                                  placeholder="请输入当月岗位工资"
                                  clearable />
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="当月绩效工资">
                        <el-input v-model="form.salaryMerit"
                                  type='number'
                                   @change="((val) => {resetVal(val, 'salaryMerit')})"
                                  placeholder="请输入当月绩效工资"
                                  clearable />
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="餐补">
                        <el-input v-model="form.salaryFood"
                                  type='number'
                                  @change="((val) => {resetVal(val, 'salaryFood')})"
                                  placeholder="请输入餐补"
                                  clearable />
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="补发">
                        <el-input v-model="form.salarySupplement"
                                  type='number'
                                  @change="((val) => {resetVal(val, 'salarySupplement')})"
                                  placeholder="请输入补发"
                                  clearable />
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="奖励">
                        <el-input v-model="form.reward"
                                  type='number'
                                  @change="((val) => {resetVal(val, 'reward')})"
                                  placeholder="请输入奖励"
                                  clearable />
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="考勤扣款">
                        <el-input v-model="form.attendanceDeduction"
                                  type='number'
                                  @change="((val) => {resetVal(val, 'attendanceDeduction')})"
                                  placeholder="请输入考勤扣款"
                                  clearable />
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="罚款/保密费/其他">
                        <el-input v-model="form.fine"
                                  type='number'
                                  @change="((val) => {resetVal(val, 'fine')})"
                                  placeholder="请输入罚款/保密费/其他"
                                  clearable />
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="应发合计">
                        <!-- <el-input v-model="form.salaryTotal" -->
                        <el-input v-model="setSalaryTotal"
                                  disabled
                                  type='number'
                                  placeholder="请输入应发合计"
                                  clearable />
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="社保（个人扣）">
                        <el-input v-model="form.socialSecurityStaff"
                                  type='number'
                                  @change="((val) => {resetVal(val, 'socialSecurityStaff')})"
                                  placeholder="请输入社保（个人扣）"
                                  clearable />
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="社保（公司扣）">
                        <el-input v-model="form.socialSecurityCompany"
                                  type='number'
                                  @change="((val) => {resetVal(val, 'socialSecurityCompany')})"
                                  placeholder="请输入社保（公司扣）"
                                  clearable />
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="公积金（个人扣）">
                        <el-input v-model="form.accumulationFundStaff"
                                  type='number'
                                  @change="((val) => {resetVal(val, 'accumulationFundStaff')})"
                                  placeholder="请输入公积金（个人扣）"
                                  clearable />
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="公积金（公司扣）">
                        <el-input v-model="form.accumulationFundCompany"
                                  type='number'
                                  @change="((val) => {resetVal(val, 'accumulationFundCompany')})"
                                  placeholder="请输入公积金（公司扣）"
                                  clearable />
                    </el-form-item>
                </el-col>

                <el-col :span="24">
                    <el-form-item label="计税工资">
                        <!-- <el-input v-model="form.salaryTax" -->
                        <el-input v-model="setSalaryTax"
                                  disabled
                                  type='number'
                                  placeholder="请输入计税工资"
                                  clearable />
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="个人所得税">
                        <el-input v-model="form.individualIncomeTax"
                                  type='number'
                                  @change="((val) => {resetVal(val, 'individualIncomeTax')})"
                                  placeholder="请输入个人所得税"
                                  clearable />
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="其他扣款">
                        <el-input v-model="form.otherDeductions"
                                  type='number'
                                  @change="((val) => {resetVal(val, 'otherDeductions')})"
                                  placeholder="请输入其他扣款"
                                  clearable />
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="出差补贴">
                        <el-input v-model="form.travelAllowance"
                                  type='number'
                                  @change="((val) => {resetVal(val, 'travelAllowance')})"
                                  placeholder="请输入出差补贴"
                                  clearable />
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="实发工资"
                                  prop="netSalary">
                        <!-- <el-input v-model="form.netSalary" -->
                        <el-input v-model="setNetSalary"
                                  disabled
                                  type='number'
                                  placeholder="请输入实发工资"
                                  clearable />
                    </el-form-item>
                </el-col>

                <el-col :span="24">
                    <el-form-item label="备注">
                        <el-input v-model="form.mark"
                                  type="textarea"
                                  placeholder="请输入备注"
                                  clearable />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-form-item class="footer">
                <el-button type="danger"
                           @click="close">取消</el-button>
                <el-button type="primary"
                           @click="onSubmit">确认</el-button>
            </el-form-item>
        </el-form>

    </el-dialog>
</template>
<script>
import humanResourcesManageApi from "@/api/humanResourcesManageApi.js";
import rsa from "@/utils/encryption/rsa"; // RSA加解密
export default {
    name: "popUpdateProjectMoney",
    components: {},
    props: {
        formData: {
            type: Object,
            default: new Object(),
        },

        //用户id
        userId: {},
    },
    watch: {
        formData: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                newVal &&
                    (this.form = this.commonJs.deepCopy(
                        Object.assign({}, this.form, newVal)
                    ));
            },
        },

        //实发工资
        setNetSalary: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                if (newVal) {
                    this.form.netSalary = newVal;
                    this.$nextTick(() => {
                        this.$refs["form"].validateField("netSalary");
                        this.$forceUpdate();
                    });
                }
            },
        },

        //应发合计
        setSalaryTotal: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                if (newVal) {
                    this.form.salaryTotal = newVal;
                    this.$forceUpdate();
                }
            },
        },

        //计税工资
        setSalaryTax: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                if (newVal) {
                    this.form.salaryTax = newVal;
                    this.$forceUpdate();
                }
            },
        },
    },
    data() {
        return {
            isloading: false,
            form: {
                title: "",
                money: "", //所占合同金额
            },
            rules: {
                payTime: [
                    {
                        required: true,
                        message: "请选择日期",
                    },
                ],
                salaryStandard: [
                    {
                        required: true,
                        message: "请输入薪资标准",
                        trigger: "change",
                    },
                ],
                netSalary: [
                    {
                        required: true,
                        message: "请输入实发工资",
                        trigger: "change",
                    },
                ],
            },
        };
    },
    mounted() {},
    methods: {
        // 提交表单
        onSubmit() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.save(
                        this.form.title === "新增"
                            ? "salarySave"
                            : "salaryUpdate"
                    );
                } else {
                    this.$message({
                        message: "请检查必填内容是否填写！",
                        type: "error",
                    });
                    return;
                }
            });
        },
        //保存
        save(interName) {
            this.isloading = true;
            let e = this.form;
            this.$store.dispatch("GetPublicKey").then(v=>{
                //后端生成公钥
                let serverPublicKey = v.content;
                humanResourcesManageApi[interName](
                    Object.assign(
                        {},
                        this.form,
                        { userId: this.userId },
                        {
                            salaryStandard: rsa.rsaEncryption(e.salaryStandard,serverPublicKey),
                            salaryBase: rsa.rsaEncryption(e.salaryBase,serverPublicKey),
                            salaryPost: rsa.rsaEncryption(e.salaryPost,serverPublicKey),
                            salaryMerit: rsa.rsaEncryption(e.salaryMerit,serverPublicKey),
                            salaryFood: rsa.rsaEncryption(e.salaryFood,serverPublicKey),
                            salarySupplement: rsa.rsaEncryption(e.salarySupplement,serverPublicKey),
                            reward: rsa.rsaEncryption(e.reward,serverPublicKey),
                            attendanceDeduction: rsa.rsaEncryption(e.attendanceDeduction,serverPublicKey),
                            fine: rsa.rsaEncryption(e.fine,serverPublicKey),
                            salaryTotal: rsa.rsaEncryption(e.salaryTotal,serverPublicKey),
                            socialSecurityStaff: rsa.rsaEncryption(e.socialSecurityStaff,serverPublicKey),
                            socialSecurityCompany: rsa.rsaEncryption(
                                e.socialSecurityCompany,serverPublicKey
                            ),
                            accumulationFundStaff: rsa.rsaEncryption(
                                e.accumulationFundStaff,serverPublicKey
                            ),
                            accumulationFundCompany: rsa.rsaEncryption(
                                e.accumulationFundCompany,serverPublicKey
                            ),
                            salaryTax: rsa.rsaEncryption(e.salaryTax,serverPublicKey),
                            individualIncomeTax: rsa.rsaEncryption(e.individualIncomeTax,serverPublicKey),
                            otherDeductions: rsa.rsaEncryption(e.otherDeductions,serverPublicKey),
                            travelAllowance: rsa.rsaEncryption(e.travelAllowance,serverPublicKey),
                            netSalary: rsa.rsaEncryption(e.netSalary,serverPublicKey),
                            publicKey:serverPublicKey
                        }
                    )
                )
                    .then(() => {
                        this.$message({
                            message: "恭喜！" + this.form.title + "成功。",
                            type: "success",
                        });
                        this.isloading = false;
                        this.$emit("close");
                        this.$emit("refresh");
                    })
                    .catch(() => {
                        this.isloading = false;
                    });
            });

        },

        //输入框输入内容重置
        resetVal(val,filed){
            // this.form[filed] = parseFloat(val).toFixed(2) ;
            this.form[filed] = (Math.floor(val * 100) / 100).toFixed(2) ;
        },

        // 关闭弹窗
        close() {
            this.$emit("close");
        },
    },
    computed: {
        //实发工资
        setNetSalary() {
            let netSalary = (
                parseFloat(this.setSalaryTax || 0) -
                parseFloat(this.form.individualIncomeTax || 0) -
                parseFloat(this.form.otherDeductions || 0) +
                parseFloat(this.form.travelAllowance || 0)
            ).toFixed(2);
            return netSalary;
        },

        //应发合计
        setSalaryTotal() {
            let salaryTotal = (
                parseFloat(this.form.salaryStandard || 0) +
                parseFloat(this.form.salaryMerit || 0) +
                parseFloat(this.form.salaryFood || 0) +
                parseFloat(this.form.salarySupplement || 0) +
                parseFloat(this.form.reward || 0) -
                parseFloat(this.form.attendanceDeduction || 0) -
                parseFloat(this.form.fine || 0)
            ).toFixed(2);
            return salaryTotal;
        },

        //计税工资
        setSalaryTax() {
            let salaryTax = (
                parseFloat(this.setSalaryTotal || 0) -
                parseFloat(this.form.socialSecurityStaff || 0) -
                parseFloat(this.form.accumulationFundStaff || 0)
            ).toFixed(2);
            return salaryTax;
        },
    },
};
</script>
<style scoped>
</style>
// 添加工资条 // popAddSingleSalary
<template>
    <div class="popAddSingleSalary">
        <el-dialog
            :title="dialogTitle"
            :close-on-click-modal="false"
            :visible.sync="dialogVisible"
            :modal-append-to-body="true"
            custom-class="popAddSingleSalaryDialog"
            :append-to-body="true"
            width="1360px"
            :before-close="close"
        >
            <el-form
                :model="form"
                ref="form"
                v-loading="loadingDetail"
                :rules="formRules"
                label-position="top"
                :inline="false"
            >
                <div class="baseInfo">
                    <div class="info">
                        <div class="field">
                            <!-- 应发合计：税前收入-税前扣减； -->
                            应发合计：{{
                                shouldSend || ""
                            }}
                            &nbsp;&nbsp;|&nbsp;&nbsp;实发工资：{{
                                salary || ""
                            }}
                            <!-- 工资税费合计：税前收入-税前扣减+公司社保+公司公积金+出差补贴 -->
                            &nbsp;&nbsp;|&nbsp;&nbsp;工资税费合计：{{
                                salaryTaxTotal || ""
                            }}
                        </div>
                    </div>
                </div>

                <el-row :gutter="60">
                    <el-col :span="8">
                        <el-form-item label="员工" prop="userId">
                            <select-person-in-wage-dept-tree
                                v-model="form.userId"
                                :disabled="salaryOperationType === 'details'"
                            ></select-person-in-wage-dept-tree>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="月份" prop="month">
                            <el-date-picker
                                v-model="form.month"
                                type="month"
                                disabled
                                value-format="yyyy-MM"
                                format="yyyy年MM月"
                                placeholder="选择月份"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>

                <div class="popItemBox">
                    <el-tabs type="border-card">
                        <el-tab-pane label="工资组成">
                            <el-row :gutter="60">
                                <el-col :span="8">
                                    <el-form-item
                                        label="薪资标准"
                                        prop="salaryStandard"
                                    >
                                        <el-input-money
                                            v-model="form.salaryStandard"
                                            :disabled="
                                                salaryOperationType ===
                                                    'details'
                                            "
                                            placeholder="请输入薪资标准"
                                        ></el-input-money>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="基础工资">
                                        <span slot="label"
                                            >基础工资&nbsp;&nbsp;
                                            <el-tooltip
                                                effect="dark"
                                                content="只是表达薪资的组成方式，不计入统计"
                                            >
                                                <i class="el-icon-warning"></i>
                                            </el-tooltip>
                                        </span>
                                        <el-input-money
                                            v-model="form.salaryBase"
                                            :disabled="
                                                salaryOperationType ===
                                                    'details'
                                            "
                                            placeholder="请输入基础工资"
                                        ></el-input-money>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="岗位工资">
                                        <span slot="label"
                                            >岗位工资&nbsp;&nbsp;
                                            <el-tooltip
                                                effect="dark"
                                                content="只是表达薪资的组成方式，不计入统计"
                                            >
                                                <i class="el-icon-warning"></i>
                                            </el-tooltip>
                                        </span>
                                        <el-input-money
                                            v-model="form.salaryPost"
                                            :disabled="
                                                salaryOperationType ===
                                                    'details'
                                            "
                                            placeholder="请输入岗位工资"
                                        ></el-input-money>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="绩效工资">
                                        <el-input-money
                                            v-model="form.salaryMerit"
                                            :disabled="
                                                salaryOperationType ===
                                                    'details'
                                            "
                                            placeholder="请输入绩效工资"
                                        ></el-input-money>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="补贴">
                                        <el-input-money
                                            v-model="form.subsidy"
                                            :disabled="
                                                salaryOperationType ===
                                                    'details'
                                            "
                                            placeholder="请输入补贴"
                                        ></el-input-money>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="在职延续奖">
                                        <el-input-money
                                            v-model="form.duringBonus"
                                            :disabled="
                                                salaryOperationType ===
                                                    'details'
                                            "
                                            placeholder="请输入在职延续奖"
                                        ></el-input-money>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="餐补">
                                        <el-input-money
                                            v-model="form.salaryFood"
                                            :disabled="
                                                salaryOperationType ===
                                                    'details'
                                            "
                                            placeholder="请输入餐补"
                                        ></el-input-money>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="其他">
                                        <el-input-money
                                            v-model="form.salaryOther"
                                            :disabled="
                                                salaryOperationType ===
                                                    'details'
                                            "
                                            placeholder="请输入其他"
                                        ></el-input-money>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <div class="countBox">
                                <div class="countItem">
                                    <div class="count">
                                        {{ salaryAll || "" }}
                                    </div>
                                    <div class="unit">总工资（元）</div>
                                    <div class="line"></div>
                                </div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>

                <div class="popItemBox">
                    <el-tabs type="border-card">
                        <el-tab-pane label="扣减组成">
                            <el-row :gutter="60">
                                <el-col :span="8">
                                    <el-form-item label="考勤扣款">
                                        <el-input-money
                                            v-model="form.attendanceDeduction"
                                            :disabled="
                                                salaryOperationType ===
                                                    'details'
                                            "
                                            placeholder="请输入考勤扣款"
                                        ></el-input-money>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="罚款/保密费/其他">
                                        <el-input-money
                                            v-model="form.fine"
                                            :disabled="
                                                salaryOperationType ===
                                                    'details'
                                            "
                                            placeholder="请输入罚款/保密费/其他"
                                        ></el-input-money>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="其他扣减">
                                        <el-input-money
                                            v-model="form.otherDeductions"
                                            :disabled="
                                                salaryOperationType ===
                                                    'details'
                                            "
                                            placeholder="请输入其他扣减"
                                        ></el-input-money>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="备注">
                                        <el-input
                                            v-model="form.remark"
                                            :disabled="
                                                salaryOperationType ===
                                                    'details'
                                            "
                                            placeholder="请输入备注"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <div class="countBox">
                                <div class="countItem">
                                    <div class="count">
                                        {{ deductionTotal || "" }}
                                    </div>
                                    <div class="unit">总扣减（元）</div>
                                    <div class="line"></div>
                                </div>
                                <div class="countItem">
                                    <div class="count">
                                        {{ beforeTaxDeduction || "" }}
                                    </div>
                                    <div class="unit">税前扣减（元）</div>
                                    <div class="line"></div>
                                </div>
                                <div class="countItem">
                                    <div class="count">
                                        {{ form.afterTaxDeduction || "" }}
                                    </div>
                                    <div class="unit">税后扣减（元）</div>
                                    <div class="line"></div>
                                </div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <!-- 五险一金表单 -->
                <com-calcsocial-security
                    ref="comCalcsocialSecurity"
                    :disabled="salaryOperationType === 'details'"
                    :formData="form"
                    :refreshWxyj="refreshWxyj"
                    @changeSecurity="handleChangeSecurity"
                ></com-calcsocial-security>

                <div class="popItemBox">
                    <el-tabs type="border-card">
                        <el-tab-pane label="其他">
                            <el-row :gutter="60">
                                <el-col :span="8">
                                    <el-form-item label="个人所得税">
                                        <el-input-money
                                            v-model="form.personalIncomeTax"
                                            :disabled="
                                                salaryOperationType ===
                                                    'details'
                                            "
                                            placeholder="请输入个人所得税"
                                        ></el-input-money>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="税后扣减">
                                        <el-input-money
                                            v-model="form.afterTaxDeduction"
                                            :disabled="
                                                salaryOperationType ===
                                                    'details'
                                            "
                                            placeholder="请输入税后扣减"
                                        ></el-input-money>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="出差补贴">
                                        <el-input-money
                                            v-model="form.tripSubsidy"
                                            :disabled="
                                                salaryOperationType ===
                                                    'details'
                                            "
                                            placeholder="请输入出差补贴"
                                        ></el-input-money>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                    </el-tabs>
                </div>

                <div class="footer">
                    <el-button type="danger" @click="close">取消</el-button>
                    <el-button
                        v-if="salaryOperationType !== 'details'"
                        type="primary"
                        :loading="isSaveLoading"
                        @click="save()"
                        >确定</el-button
                    >
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import SelectPersonInWageDeptTree from "../../../modleComponents/person/selectPersonInWageDeptTree";
import ComCalcsocialSecurity from "./comCalcsocialSecurity";
import ElInputMoney from "../../../components/form/elInputMoney";
// import SelectAllCompanyPerson from "../../../modleComponents/person/selectAllCompanyPerson";
import { mapGetters } from "vuex";
import API from "@/api/finance.js";
import rsa from "@/utils/encryption/rsa"; // RSA解密
export default {
    name: "popAddSingleSalary",

    props: {
        // 查询用id
        id: {
            type: String,
        },

        // 操作类型 新增编辑工资表时  新增/编辑 add||edit||details
        operationType: {},

        // 操作个人工资表时  新增还是编辑
        salaryOperationType: {},

        // 新增操作时带过来的数据
        salaryRowData: {},

        // 编辑操作时带过来的  id  以及pwd
        detailParms: {},
    },

    components: {
        SelectPersonInWageDeptTree,
        ComCalcsocialSecurity,
        ElInputMoney,
        // SelectAllCompanyPerson,
    },

    data() {
        return {
            dialogVisible: true,
            isSaveLoading: false,
            loadingDetail: false, // 加载详情
            serverPublicKey: "", // 后端公钥
            searchForm: {}, // 查询详情时需要使用的参数
            form: {
                month: "",
                salaryAll: 0, //总工资
                deductionTotal: 0, // 扣减金额
                fiveInsurances: 0, //五险一金
                personalIncomeTax: 0, //个人所得税
                salary: 0, //实发工资
                userId: "", //用户id
                salaryStandard: "", // 薪资标准
                salaryBase: "", // 基础工资
                salaryPost: "", // 岗位工资
                salaryMerit: "", // 绩效工资
                salaryFood: "", // 餐补
                salaryOther: "", // 其他
                attendanceDeduction: "", // 考勤扣减
                fine: "", // 罚款
                otherDeductions: "", // 其他扣减
                remark: "", // 备注
                endowmentInsuranceBase: "", // 养老保险基数
                endowmentInsuranceStaff: "", // 养老保险个人扣
                endowmentInsuranceCompany: "", // 养老保险公司扣
                medicalInsuranceBase: "", // 医疗保险基数
                medicalInsuranceCompany: "", // 医疗保险公司扣
                medicalInsuranceStaff: "", // 医疗保险个人扣
                unemploymentInsuranceBase: "", // 失业保险基数
                unemploymentInsuranceCompany: "", // 失业保险公司扣
                unemploymentInsuranceStaff: "", // 失业保险个人扣
                employmentInjuryInsuranceBase: "", // 工伤保险基数
                employmentInjuryInsuranceCompany: "", // 工伤保险公司扣
                birthInsuranceBase: "", // 生育保险基数
                birthInsuranceCompany: "", // 生育保险公司扣
                accumulationFundBase: "", // 公积金基数
                seriousSickInsurance: "", // 大病医疗保险金额
                accumulationFundCompany: Number(0).toFixed(2), // 公积金公司扣
                accumulationFundStaff: Number(0).toFixed(2), // 公积金个人扣
                subsidy: "", // 补贴
                duringBonus: "", // 在职延续奖
                tripSubsidy: "", // 出差补贴
            },
            formRules: {
                userId: {
                    required: true,
                    message: "请选择员工",
                    trigger: "change",
                }, // 员工id
            },
            options: [], // 缴税费类别
            detailsInfo: {}, // 详情信息
            editRowIndex: null, // 正在编辑的行
            needApply: false, // 是否需要审核侧边栏
            popSelectTicketVisible: false, // 选择单据弹窗显示

            fiveInsurances: 0, // 五险一金总数
            personfiveInsurances: 0, // 五险一金个人总数
            companyfiveInsurances: 0, // 五险一金单位总数
            refreshWxyj: 0, // 是否更新五险一金  更父组件带form进入
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 获取详情
        getPersonWageDetail() {
            this.loadingDetail = true;
            API.getPersonWageDetail(
                Object.assign(
                    this.searchForm,
                    this.serverPublicKey
                        ? { publicKey: this.serverPublicKey }
                        : {}, // 服务器公钥
                    this.fontRsaKey
                        ? { frontPublicKey: this.fontRsaKey.publicKey }
                        : {}, //  前端生成的RSA 公钥
                    this.searchForm.pwd
                        ? {
                              pwd: this.commonJs.rsaEncryption(
                                  this.searchForm.pwd,
                                  this.serverPublicKey
                              ),
                          }
                        : {}
                )
            )
                .then((res) => {
                    // TODO v3.13 优化加密字段
                    let content = res.content;
                    this.form = Object.assign(
                        {},
                        this.form,
                        content,
                        {},
                        {
                            accumulationFundBase:
                                rsa.rsaDecrypt(content.accumulationFundBase) ||
                                "****", //
                            accumulationFundCompany:
                                rsa.rsaDecrypt(
                                    content.accumulationFundCompany
                                ) || "****", //
                            accumulationFundCompanyPercent:
                                rsa.rsaDecrypt(
                                    content.accumulationFundCompanyPercent
                                ) || "****", //
                            accumulationFundStaff:
                                rsa.rsaDecrypt(content.accumulationFundStaff) ||
                                "****", //
                            accumulationFundStaffPercent:
                                rsa.rsaDecrypt(
                                    content.accumulationFundStaffPercent
                                ) || "****", //
                            attendanceDeduction:
                                rsa.rsaDecrypt(content.attendanceDeduction) ||
                                "****", //
                            balance: rsa.rsaDecrypt(content.balance) || "****", //
                            birthInsuranceBase:
                                rsa.rsaDecrypt(content.birthInsuranceBase) ||
                                "****", //
                            birthInsuranceCompany:
                                rsa.rsaDecrypt(content.birthInsuranceCompany) ||
                                "****", //
                            birthInsuranceCompanyPercent:
                                rsa.rsaDecrypt(
                                    content.birthInsuranceCompanyPercent
                                ) || "****", //
                            birthInsuranceStaff:
                                rsa.rsaDecrypt(content.birthInsuranceStaff) ||
                                "****", //
                            birthInsuranceStaffPercent:
                                rsa.rsaDecrypt(
                                    content.birthInsuranceStaffPercent
                                ) || "****", //
                            deductionTotal:
                                rsa.rsaDecrypt(content.deductionTotal) ||
                                "****", //
                            employmentInjuryInsuranceBase:
                                rsa.rsaDecrypt(
                                    content.employmentInjuryInsuranceBase
                                ) || "****", //
                            employmentInjuryInsuranceCompany:
                                rsa.rsaDecrypt(
                                    content.employmentInjuryInsuranceCompany
                                ) || "****", //
                            employmentInjuryInsuranceCompanyPercent:
                                rsa.rsaDecrypt(
                                    content.employmentInjuryInsuranceCompanyPercent
                                ) || "****", //
                            employmentInjuryInsuranceStaff:
                                rsa.rsaDecrypt(
                                    content.employmentInjuryInsuranceStaff
                                ) || "****", //
                            employmentInjuryInsuranceStaffPercent:
                                rsa.rsaDecrypt(
                                    content.employmentInjuryInsuranceStaffPercent
                                ) || "****", //
                            endowmentInsuranceBase:
                                rsa.rsaDecrypt(
                                    content.endowmentInsuranceBase
                                ) || "****", //
                            endowmentInsuranceCompany:
                                rsa.rsaDecrypt(
                                    content.endowmentInsuranceCompany
                                ) || "****", //
                            endowmentInsuranceCompanyPercent:
                                rsa.rsaDecrypt(
                                    content.endowmentInsuranceCompanyPercent
                                ) || "****", //
                            endowmentInsuranceStaff:
                                rsa.rsaDecrypt(
                                    content.endowmentInsuranceStaff
                                ) || "****", //
                            endowmentInsuranceStaffPercent:
                                rsa.rsaDecrypt(
                                    content.endowmentInsuranceStaffPercent
                                ) || "****", //
                            fine: rsa.rsaDecrypt(content.fine) || "****", //
                            medicalInsuranceBase:
                                rsa.rsaDecrypt(content.medicalInsuranceBase) ||
                                "****", //
                            medicalInsuranceCompany:
                                rsa.rsaDecrypt(
                                    content.medicalInsuranceCompany
                                ) || "****", //
                            medicalInsuranceCompanyPercent:
                                rsa.rsaDecrypt(
                                    content.medicalInsuranceCompanyPercent
                                ) || "****", //
                            medicalInsuranceStaff:
                                rsa.rsaDecrypt(content.medicalInsuranceStaff) ||
                                "****", //
                            medicalInsuranceStaffPercent:
                                rsa.rsaDecrypt(
                                    content.medicalInsuranceStaffPercent
                                ) || "****", //
                            otherDeductions:
                                rsa.rsaDecrypt(content.otherDeductions) ||
                                "****", //
                            personalIncomeTax:
                                rsa.rsaDecrypt(content.personalIncomeTax) ||
                                "****", //
                            salary: rsa.rsaDecrypt(content.salary) || "****", //
                            subsidy: rsa.rsaDecrypt(content.subsidy) || "****", //
                            duringBonus:
                                rsa.rsaDecrypt(content.duringBonus) || "****", //
                            tripSubsidy:
                                rsa.rsaDecrypt(content.tripSubsidy) || "****", //
                            salaryBase:
                                rsa.rsaDecrypt(content.salaryBase) || "****", //
                            salaryFood:
                                rsa.rsaDecrypt(content.salaryFood) || "****", //
                            salaryMerit:
                                rsa.rsaDecrypt(content.salaryMerit) || "****", //
                            salaryOther:
                                rsa.rsaDecrypt(content.salaryOther) || "****", //
                            salaryPost:
                                rsa.rsaDecrypt(content.salaryPost) || "****", //
                            salaryStandard:
                                rsa.rsaDecrypt(content.salaryStandard) ||
                                "****", //
                            salaryTaxTotal:
                                rsa.rsaDecrypt(content.salaryTaxTotal) ||
                                "****", //
                            socialSecurityCompany:
                                rsa.rsaDecrypt(content.socialSecurityCompany) ||
                                "****", //
                            socialSecurityStaff:
                                rsa.rsaDecrypt(content.socialSecurityStaff) ||
                                "****", //
                            unemploymentInsuranceBase:
                                rsa.rsaDecrypt(
                                    content.unemploymentInsuranceBase
                                ) || "****", //
                            unemploymentInsuranceCompany:
                                rsa.rsaDecrypt(
                                    content.unemploymentInsuranceCompany
                                ) || "****", //
                            unemploymentInsuranceCompanyPercent:
                                rsa.rsaDecrypt(
                                    content.unemploymentInsuranceCompanyPercent
                                ) || "****", //
                            unemploymentInsuranceStaff:
                                rsa.rsaDecrypt(
                                    content.unemploymentInsuranceStaff
                                ) || "****", //
                            unemploymentInsuranceStaffPercent:
                                rsa.rsaDecrypt(
                                    content.unemploymentInsuranceStaffPercent
                                ) || "****", //
                            seriousSickInsurance:
                                rsa.rsaDecrypt(content.seriousSickInsurance) ||
                                "****", //
                            afterTaxDeduction:
                                rsa.rsaDecrypt(content.afterTaxDeduction) ||
                                "****", // 税后扣减
                        }
                    );

                    // 更新五险一金 传递
                    this.refreshWxyj = Math.random();
                })
                .finally(() => {
                    this.loadingDetail = false;
                });
        },

        // 解密数据
        entryMap(map) {
            console.log(map);
            let nmap = {};
            // for (let i in map) {
            //     console.log(map[i].length)
            //     // if (map[i].length > 70 && i !== "remark") {
            //     //     console.log(map[i])
            //     //     nmap[i] = rsa.rsaDecrypt(map[i]) || "****";
            //     // }
            // }
            console.log(nmap);
            return nmap;
        },

        // 保存工资条
        save() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.isSaveLoading = true;
                    this.$store
                        .dispatch("GetPublicKey")
                        .then((v) => {
                            this.serverPublicKey = v.content;
                            API.savePersonWage(
                                Object.assign({}, this.form, {
                                    accumulationFundBase: this.commonJs.rsaEncryption(
                                        this.form.accumulationFundBase,
                                        this.serverPublicKey
                                    ),
                                    accumulationFundCompany: this.commonJs.rsaEncryption(
                                        this.form.accumulationFundCompany,
                                        this.serverPublicKey
                                    ),
                                    accumulationFundStaff: this.commonJs.rsaEncryption(
                                        this.form.accumulationFundStaff,
                                        this.serverPublicKey
                                    ),
                                    attendanceDeduction: this.commonJs.rsaEncryption(
                                        this.form.attendanceDeduction,
                                        this.serverPublicKey
                                    ),
                                    birthInsuranceBase: this.commonJs.rsaEncryption(
                                        this.form.birthInsuranceBase,
                                        this.serverPublicKey
                                    ),
                                    birthInsuranceCompany: this.commonJs.rsaEncryption(
                                        this.form.birthInsuranceCompany,
                                        this.serverPublicKey
                                    ),
                                    subsidy: this.commonJs.rsaEncryption(
                                        this.form.subsidy,
                                        this.serverPublicKey
                                    ),
                                    duringBonus: this.commonJs.rsaEncryption(
                                        this.form.duringBonus,
                                        this.serverPublicKey
                                    ),
                                    tripSubsidy: this.commonJs.rsaEncryption(
                                        this.form.tripSubsidy,
                                        this.serverPublicKey
                                    ),
                                    deductionTotal: this.commonJs.rsaEncryption(
                                        this.deductionTotal,
                                        this.serverPublicKey
                                    ), // 扣减金额
                                    employmentInjuryInsuranceBase: this.commonJs.rsaEncryption(
                                        this.form.employmentInjuryInsuranceBase,
                                        this.serverPublicKey
                                    ), // 扣减金额
                                    employmentInjuryInsuranceCompany: this.commonJs.rsaEncryption(
                                        this.form
                                            .employmentInjuryInsuranceCompany,
                                        this.serverPublicKey
                                    ),
                                    endowmentInsuranceBase: this.commonJs.rsaEncryption(
                                        this.form.endowmentInsuranceBase,
                                        this.serverPublicKey
                                    ),
                                    endowmentInsuranceCompany: this.commonJs.rsaEncryption(
                                        this.form.endowmentInsuranceCompany,
                                        this.serverPublicKey
                                    ),
                                    endowmentInsuranceStaff: this.commonJs.rsaEncryption(
                                        this.form.endowmentInsuranceStaff,
                                        this.serverPublicKey
                                    ),
                                    seriousSickInsurance: this.commonJs.rsaEncryption(
                                        this.form.seriousSickInsurance,
                                        this.serverPublicKey
                                    ),
                                    fine: this.commonJs.rsaEncryption(
                                        this.form.fine,
                                        this.serverPublicKey
                                    ),
                                    medicalInsuranceBase: this.commonJs.rsaEncryption(
                                        this.form.medicalInsuranceBase,
                                        this.serverPublicKey
                                    ),
                                    medicalInsuranceCompany: this.commonJs.rsaEncryption(
                                        this.form.medicalInsuranceCompany,
                                        this.serverPublicKey
                                    ),
                                    medicalInsuranceStaff: this.commonJs.rsaEncryption(
                                        this.form.medicalInsuranceStaff,
                                        this.serverPublicKey
                                    ),
                                    otherDeductions: this.commonJs.rsaEncryption(
                                        this.form.otherDeductions,
                                        this.serverPublicKey
                                    ),
                                    personalIncomeTax: this.commonJs.rsaEncryption(
                                        this.form.personalIncomeTax,
                                        this.serverPublicKey
                                    ),
                                    publicKey: this.serverPublicKey,
                                    salary: this.commonJs.rsaEncryption(
                                        this.personSalary,
                                        this.serverPublicKey
                                    ), // 个人应发
                                    salaryBase: this.commonJs.rsaEncryption(
                                        this.form.salaryBase,
                                        this.serverPublicKey
                                    ),
                                    salaryFood: this.commonJs.rsaEncryption(
                                        this.form.salaryFood,
                                        this.serverPublicKey
                                    ),
                                    salaryMerit: this.commonJs.rsaEncryption(
                                        this.form.salaryMerit,
                                        this.serverPublicKey
                                    ),
                                    salaryOther: this.commonJs.rsaEncryption(
                                        this.form.salaryOther,
                                        this.serverPublicKey
                                    ),
                                    salaryPost: this.commonJs.rsaEncryption(
                                        this.form.salaryPost,
                                        this.serverPublicKey
                                    ),
                                    salaryStandard: this.commonJs.rsaEncryption(
                                        this.form.salaryStandard,
                                        this.serverPublicKey
                                    ),
                                    afterTaxDeduction: this.commonJs.rsaEncryption(
                                        this.form.afterTaxDeduction,
                                        this.serverPublicKey
                                    ), // 税后扣减
                                    salaryTaxTotal: this.commonJs.rsaEncryption(
                                        this.salaryTaxTotal,
                                        this.serverPublicKey
                                    ), // 工资税费合计 个人收入 + 企业社保 + 企业公积金 - 扣减金额
                                    socialSecurityCompany: this.commonJs.rsaEncryption(
                                        (
                                            parseFloat(
                                                this.form
                                                    .endowmentInsuranceCompany
                                            ) +
                                            parseFloat(
                                                this.form
                                                    .medicalInsuranceCompany
                                            ) +
                                            parseFloat(
                                                this.form
                                                    .unemploymentInsuranceCompany
                                            ) +
                                            parseFloat(
                                                this.form
                                                    .employmentInjuryInsuranceCompany
                                            ) +
                                            parseFloat(
                                                this.form.birthInsuranceCompany
                                            )
                                        ).toFixed(2),
                                        this.serverPublicKey
                                    ), // 企业扣 社保

                                    socialSecurityStaff: this.commonJs.rsaEncryption(
                                        (
                                            parseFloat(
                                                this.form
                                                    .endowmentInsuranceStaff
                                            ) +
                                            parseFloat(
                                                this.form.medicalInsuranceStaff
                                            ) +
                                            parseFloat(
                                                this.form
                                                    .unemploymentInsuranceStaff
                                            )
                                        ).toFixed(2),
                                        this.serverPublicKey
                                    ), // 个人 扣社保

                                    unemploymentInsuranceBase: this.commonJs.rsaEncryption(
                                        this.form.unemploymentInsuranceBase,
                                        this.serverPublicKey
                                    ),
                                    unemploymentInsuranceCompany: this.commonJs.rsaEncryption(
                                        this.form.unemploymentInsuranceCompany,
                                        this.serverPublicKey
                                    ),
                                    unemploymentInsuranceStaff: this.commonJs.rsaEncryption(
                                        this.form.unemploymentInsuranceStaff,
                                        this.serverPublicKey
                                    ),
                                    employmentInjuryInsuranceStaff: this.commonJs.rsaEncryption(
                                        this.form
                                            .employmentInjuryInsuranceStaff,
                                        this.serverPublicKey
                                    ),
                                    birthInsuranceStaff: this.commonJs.rsaEncryption(
                                        this.form.birthInsuranceStaff,
                                        this.serverPublicKey
                                    ),
                                })
                            )
                                .then((res) => {
                                    // 先存库的id
                                    this.form.id = res.content;
                                    this.$message({
                                        message: res.message || "保存成功",
                                        type: "success",
                                    });
                                    // 新增时 的个人工资新增或者编辑的row 一行数据
                                    this.$emit("refresh");
                                    // 编辑时
                                    this.$emit("close"); //关闭弹框
                                })
                                .finally(() => {
                                    this.isSaveLoading = false;
                                });
                        })
                        .catch(() => {
                            this.isSaveLoading = false;
                        });
                } else {
                    this.$message({
                        message: "请检查必填内容是否填写！",
                        type: "error",
                    });
                    return;
                }
            });
        },

        // 关闭弹窗
        close() {
            this.$emit("close");
        },

        /*  获取后台公钥  、  生成前端加解密公钥、私钥 */
        entryRsaKey() {
            this.loadingDetail = true;
            this.$store.dispatch("getSalaryKey").then((v) => {
                this.fontRsaKey = v;
                this.$store
                    .dispatch("GetPublicKey")
                    .then((v) => {
                        //后端生成公钥
                        this.serverPublicKey = v.content;
                        this.getPersonWageDetail();
                    })
                    .catch(() => {
                        this.loadingDetail = false;
                    });
            });
        },
        // 修改了五险一金表单
        handleChangeSecurity(data) {
            this.form = Object.assign({}, this.form, data);

            // 五险一金总数
            this.fiveInsurances =
                (this.$refs.comCalcsocialSecurity &&
                    this.$refs.comCalcsocialSecurity.fiveInsurances) ||
                0;
            // 五险一金个人总数
            this.personfiveInsurances =
                (this.$refs.comCalcsocialSecurity &&
                    this.$refs.comCalcsocialSecurity.personfiveInsurances) ||
                0;

            // 五险一金单位总数
            this.companyfiveInsurances =
                (this.$refs.comCalcsocialSecurity &&
                    this.$refs.comCalcsocialSecurity.companyfiveInsurances) ||
                0;
        },
    },

    computed: {
        ...mapGetters(["applyType"]),
        // 显示标题
        dialogTitle() {
            let title = "";
            if (this.salaryOperationType === "add") {
                title = "添加工资条";
            } else if (this.salaryOperationType === "edit") {
                title = "编辑工资条";
            } else {
                title = "查看工资条";
            }
            return title;
        },
        // 总工资
        salaryAll() {
            let salaryAll = (
                parseFloat(this.form.salaryStandard) +
                parseFloat(this.form.salaryMerit) +
                parseFloat(this.form.salaryFood) +
                parseFloat(this.form.salaryOther) +
                parseFloat(this.form.subsidy) +
                parseFloat(this.form.duringBonus)
            ).toFixed(2);
            return salaryAll;
        },

        // 应发合计 : 税前收入-税前扣减；
        shouldSend() {
            let num = (
                parseFloat(this.salaryAll) - parseFloat(this.beforeTaxDeduction)
            ).toFixed(2);
            return num;
        },

        // 工资税费合计：税前收入-税前扣减+公司社保+公司公积金+出差补贴
        salaryTaxTotal() {
            let num = (
                parseFloat(this.salaryAll) -
                parseFloat(this.beforeTaxDeduction) +
                parseFloat(this.companyfiveInsurances) +
                parseFloat(this.form.tripSubsidy)
            ).toFixed(2);
            return num;
        },

        // 扣减金额
        deductionTotal() {
            let deductionTotal = (
                parseFloat(this.form.attendanceDeduction) +
                parseFloat(this.form.fine) +
                parseFloat(this.form.otherDeductions) +
                parseFloat(this.form.afterTaxDeduction)
            ).toFixed(2);
            return deductionTotal;
        },
        // 税前扣减
        beforeTaxDeduction() {
            let beforeTaxDeduction = (
                parseFloat(this.form.attendanceDeduction) +
                parseFloat(this.form.fine) +
                parseFloat(this.form.otherDeductions)
            ).toFixed(2);
            return beforeTaxDeduction;
        },
        // 实发工资
        salary() {
            return (
                parseFloat(this.salaryAll) -
                parseFloat(this.personfiveInsurances) -
                parseFloat(this.deductionTotal) -
                parseFloat(this.form.personalIncomeTax)
            ).toFixed(2);
        },

        //个人应发工资  个人收入 - 个人五险一金 - 个人扣款 - 个人所得税
        personSalary() {
            return (
                parseFloat(this.salaryAll) -
                parseFloat(this.personfiveInsurances) -
                parseFloat(this.deductionTotal) -
                parseFloat(this.form.personalIncomeTax)
            ).toFixed(2);
        },
    },

    watch: {
        //新增状态时带来的 id和月份
        salaryRowData: {
            deep: true,
            immediate: true,
            handler: function(opt) {
                setTimeout(() => {
                    // 新增时
                    if (this.salaryOperationType === "add") {
                        this.form = Object.assign({}, this.form, opt, {
                            id: null,
                        });
                    }
                }, 200);
            },
        },

        /**
         * @描述：编辑操作时的工资表id 以及 查看用户密码
         * @参数: opt: {id:工资表id,pwd:查看工资输入的密码}
         */
        detailParms: {
            deep: true,
            immediate: true,
            handler: function(opt) {
                debugger;
                if (opt && this.salaryOperationType === "edit") {
                    this.searchForm = opt;
                    this.entryRsaKey();
                } else if (opt && this.salaryOperationType === "add") {
                    this.form = Object.assign({}, this.form, opt, { id: null });
                } else if (opt && this.salaryOperationType === "details") {
                    // this.form = Object.assign({}, this.form, opt, { id: null });
                    this.searchForm = opt;
                    this.entryRsaKey();
                }
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.baseInfo {
    font-size: 16px;
    padding: 20px 0 40px;
}
.inputUnit {
    height: 100%;
    width: 50px;
    background: rgb(238, 238, 238);
    transform: translateX(10px);
}
.countItem {
    width: 250px;
}
</style>
